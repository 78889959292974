.content-full-section {
  width: 100%;
}
.content-section {
  @media only screen and (min-width: 1200px) {
    width: 80%;
  }
  width: 100%;
}

.home-header-section {
  width: 100%;
  // height: 560px;
  // background: linear-gradient(160deg, rgba(248, 239, 219, 100) 2.12%, rgba(255, 255, 255, 0.92) 97.88%);
  background: linear-gradient(160deg, rgba(48, 41, 41, 1) 2.12%, rgba(109, 107, 107, 0.92) 97.88%);
  clip-path: polygon(0 0, 100% 0%, 100% 87%, 0% 100%);
  @media only screen and (max-width: 600px) {
    clip-path: polygon(0 0, 100% 0%, 100% 95%, 0% 100%);
  }
}

.home-mobile-header-section {
  width: 100%;
  height: 500px;
  background: linear-gradient(165deg, #0052FF 0%, #FF00D0 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 95%, 0% 100%);
}

.home-pattern-section{
  // background: #FBF7EE;
  background: linear-gradient(320deg, rgba(48, 41, 41, 1) 2.12%, rgba(109, 107, 107, 0.92) 97.88%);
  padding-top: 60px;
  padding-bottom: 70px;
}

.home-manager-section{
  background: #333333;
  padding-top: 60px;
  padding-bottom: 70px;
}

.home-detail-section {
  width: 100%;
  background: linear-gradient(320deg, rgba(48, 41, 41, 1) 2.12%, rgba(109, 107, 107, 0.92) 97.88%);
  // clip-path: polygon(0 13%, 100% 0%, 100% 87%, 0% 100%);
}
.home-auth-setion{
  // background: #FBF7EE;
  background: linear-gradient(320deg, rgba(48, 41, 41, 1) 2.12%, rgba(109, 107, 107, 0.92) 97.88%);
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  @media only screen and (max-width: 600px) {
    height: auto;
  }
  @media only screen and (min-height: 850px) {
    height: calc(100vh - 300px);
  }
}

.home-mobile-detail-section {
  width: 100%;
  background: linear-gradient(315deg, #E8EBF0 0%, #FAE6E6 100%);
  clip-path: polygon(0 3%, 100% 0%, 100% 97%, 0% 100%);
}

.home-applayout-section{
  // background: #FBF7EE;
  background: #383630;
  padding-top: 60px;
  padding-bottom: 10px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
.makeStyles-landingRoot-19{
  color: #009348!important;
}
.makeStyles-menuButton-22{
  font-weight: 500;
  font-size: 16px!important;
  line-height: 19px!important;
}

.makeStyles-header-34 {
  color: #009348!important;
  border: 1px solid #009348!important;
  height: 35px!important;
  background: transparent;
  font-weight: 500;
  font-size: 16px!important;
  line-height: 19px!important;
  margin-left: 16px;
  padding-left: 40px!important;
  padding-right: 40px!important;
  text-transform: none;
}
.makeStyles-root-42{
  padding-top: 70px!important;
  padding-bottom: 70px!important;
}
.makeStyles-title-47{
  width: auto!important;
}

.makeStyles-root-57{
  padding-top: 30px!important;
  padding-bottom: 30px!important;
}

.modal-video-body {
  max-width: inherit!important;
}
.video_play_icon_cover{
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  cursor: pointer;
}



.cover-login-form{
  height: 500px;
}

.fixed-bottom{
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
}

.flex-row{
  flex-direction: row;
}

.flex-column{
  flex-direction: column;
}



.MuiAppBar-root{
  z-index: 1000!important;
}

.question-tab{
  .MuiPaper-root{
    background-color: transparent!important;
  }
}
.MuiPaper-elevation1 {
  box-shadow: none!important;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 0px!important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0!important;
}
.react-viewer-close > i {
  top: 4px!important;
}
.react-viewer-icon {
  padding-top: 8px!important;
  padding-left: 1px!important;
}
.position-relative{
  position: relative;
}
.payment-input{
  position: relative;
}
.input-cover{
  position: absolute;
  width: calc(100% - 10px);
  height: 100%;
  top: 0;
  color: transparent;
}
#paymentDisable {
  input {
    pointer-events:none;
  }
  button {
    pointer-events: none;
  }
}
.StripeElement {
  display: block;
  padding: 12px 10px;
  font-family: "Source Code Pro", monospace;
  /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px; */

  border: 1px solid lightgrey;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.password-form input{
  width: 100%;
  padding: 10px 10px;
  border-radius: 5px;
  height: 47px;
  border: 1px solid lightgrey;
}
.password-form .err{
  padding: 5px 0px 0px 10px;
}

.password-form input:focus{
  outline: none;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
  rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  border: 1px solid #009348;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.payment-input input:focus{
  outline: none;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
  rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  border: 1px solid #009348;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  border: 1px solid #009348;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.side-nav-show{
  height: auto;
}