.action-remove {
    color: chocolate;
    font-size: 12px;
    text-decoration: underline;
    padding: 2px 3px;
    cursor: pointer;
}

.action-disable {
    color: rgb(128, 128, 128);
    font-size: 12px;
    text-decoration: underline;
    padding: 2px 3px;
    cursor: pointer;
}

.action-active {
    color: green;
    font-size: 12px;
    text-decoration: underline;
    padding: 2px 3px;
    cursor: pointer;
}

.ant-table thead {
    font-size: 14px;
    font-weight: 500;
}

.ant-table tbody {
    font-size: 16px;
    font-weight: 500;
}

.img-100 {
    width: 100%;
}

.pointer {
    cursor: pointer;
}

.pt-5 {
    padding-top: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-70 {
    padding-top: 70px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-30 {
    padding-left: 30px;
}

.pl-40 {
    padding-left: 40px;
}

.pr-10 {
    padding-right: 10px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-30 {
    padding-right: 30px;
}

.pr-40 {
    padding-right: 40px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-40 {
    margin-right: 40px;
}

.w-10 {
    width: 10px;
}

.w-20 {
    width: 20px;
}

.w-30 {
    width: 30px;
}

.w-40 {
    width: 40px;
}

.between-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.center-end {
    display: flex;
    align-items: center;
    justify-content: end;
}

.center-around {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.center-center {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.center-start {
    display: flex;
    align-items: center;
    justify-content: start;
}

.center-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
    display: inline-flex;
}

.errText {
    font-size: 14px;
    padding-left: 10px;
    padding-top: 3px;
    color: crimson;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.home_btn {
    background: #009348 !important;
    box-shadow: 0px 0px 4px;
    border: 1px solid #009348;
    border-radius: 4px;
    font-family: Gotham;
    font-style: normal;
    // font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF !important;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 7px;
    padding-bottom: 5px;
    cursor: pointer;
    text-transform: capitalize !important;
}

.home_btn_cancel {
    background: #5c5f5e !important;
    box-shadow: 0px 0px 4px;
    border: 1px solid #5c5f5e;
    border-radius: 4px;
    font-family: Gotham;
    font-style: normal;
    // font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF !important;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 7px;
    padding-bottom: 5px;
    cursor: pointer;
    text-transform: capitalize !important;
}

.invoice-link {
    color: #009348;
    text-decoration: underline;
}

.MuiLinearProgress-barColorPrimary {
    background-color: #009348 !important;
}

.MuiLinearProgress-colorPrimary {
    background-color: #e7e9e8 !important;
}

.MuiCircularProgress-colorPrimary {
    color: #ffffff !important;

    @media only screen and (max-width: 600px) {
        color: #3f9267 !important;
    }

}

.loading-appboard {
    .MuiCircularProgress-colorPrimary {
        color: #009348 !important;
    }
}

.green_buttonProgress {
    .MuiCircularProgress-colorPrimary {
        color: #ffffff !important;
    }
}

.antd-Tbl {
    .ant-table {
        background-color: transparent !important;

        tbody>tr:hover>td {
            background: rgba(202, 202, 202, 0.151) !important;
        }
    }

    .ant-table-thead {
        .ant-table-cell {
            background-color: transparent !important;
        }
    }

    .ant-table-empty {
        background-color: transparent !important;

        th .ant-table-cell {
            background-color: transparent !important;
        }
    }
}

.searchInputField {
    border: 1px solid #dbdbdb;
    padding: 12px 10px;
    border-radius: 3px;
    width: 100%;
    font-size: 15px;
    color: #424770;
}

.search-submit {
    position: absolute !important;
    right: 2px;
    top: 3px;
}

.text-active {
    color: #009348;
}

.text-pending {
    color: #C92127;
}

.text-disable {
    color: rgb(77, 77, 77);
}

.linear-height {
    height: 4px;
}

.linear-height-border {
    height: 4px;
    border-bottom: 1px solid rgb(170, 170, 170);
}

.loading-appboard {
    height: 400px;
    width: 100%;
}

.loading-text {
    font-size: 18px;
    color: #808181;
    width: 100%;
    text-align: center;
}

.posAlert {
    position: absolute;
    right: 0px;
    top: 7px;
    z-index: 5;

    .MuiButtonBase-root {
        min-width: 32px !important;
    }
}

.term-policy {
    font-size: 16px;
    line-height: 24px;

    div {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.table-responsive {
    overflow-x: auto;
}

.percentTable {
    tr>th {
        font-size: 16px;
        font-weight: 600;
        color: white;
        min-width: 100px;
        text-align: center;
    }

    tr>td {
        text-align: center;
    }

    font-size: 16px;
    font-weight: 600;
    color: white;
}

.percentContent {
    table {
        border-collapse: collapse;
    }

    tr>th {
        font-size: 16px;
        font-weight: 600;
        color: white;
        min-width: 100px;
        text-align: center;
    }

    tr>td {
        text-align: center;
        border: 1px solid #a3a3a3;
        padding: 2px;
        text-align: center;
        color: white;
    }

    tr>td:nth-child(1) {
        border: 1px transparent
    }
}

.inputTable {
    overflow-y: auto;
    margin-bottom: 20px;

    table {
        width: 100%;
        border-collapse: collapse;

    }

    .scale50 {
        font-size: 8px;

        select {
            font-size: 8px;
        }

        input {
            font-size: 8px;
        }

        .ant-select-selection-item {
            font-size: 8px;
        }

        .ant-select-dropdown .ant-select-item {
            font-size: 8px !important;
        }
    }

    .scale75 {
        font-size: 11px;

        select {
            font-size: 11px;
        }

        input {
            font-size: 11px;
        }

        .ant-select-selection-item {
            font-size: 11px;
        }

        .ant-select-dropdown .ant-select-item {
            font-size: 11px !important;
        }
    }

    .scale90 {
        font-size: 14px;

        select {
            font-size: 14px;
        }

        input {
            font-size: 14px;
        }

        .ant-select-selection-item {
            font-size: 14px;
        }

        .ant-select-dropdown .ant-select-item {
            font-size: 14px !important;
        }
    }

    .scale100 {
        font-size: 15px;

        select {
            font-size: 15px;
        }

        input {
            font-size: 15px;
        }

        .ant-select-selection-item {
            font-size: 15px;
        }

        .ant-select-dropdown .ant-select-item {
            font-size: 15px !important;
        }
    }

    .scale125 {
        font-size: 18px;

        select {
            font-size: 18px;
        }

        input {
            font-size: 18px;
        }

        .ant-select-selection-item {
            font-size: 18px;
        }

        .ant-select-dropdown .ant-select-item {
            font-size: 18px !important;
        }
    }

    .scale150 {
        font-size: 22px;

        select {
            font-size: 22px;
        }

        input {
            font-size: 22px;
        }

        .ant-select-selection-item {
            font-size: 22px;
        }

        .ant-select-dropdown .ant-select-item {
            font-size: 22px !important;
        }
    }

    .scale200 {
        font-size: 28px;

        select {
            font-size: 28px;
        }

        input {
            font-size: 28px;
        }

        .ant-select-selection-item {
            font-size: 28px;
        }

        .ant-select-dropdown .ant-select-item {
            font-size: 28px !important;
        }
    }

    thead {
        position: sticky;
        top: 0;
    }

    thead th {
        background-color: #fff;
        /* Ensure the header is visible over the scrolled content */
        z-index: 1;
        /* Make sure the header is above the scrolled content */
    }

    td {
        border: 1px solid #a3a3a3;
        padding: 2px;
        text-align: center;
        color: white;
    }

    th {
        border: 1px solid #a3a3a3;
        background-color: white;
        padding: 2px;
        text-align: center;
    }

    th.idbox {
        min-width: 20px;
    }

    select {
        background-color: transparent;
        color: white;

        option {
            background-color: #5c5f5e;
        }
    }

    input {
        width: 100%;
        border: none;
        background-color: transparent;
        color: white;
    }

    input:focus {
        outline: none;
        border: none;
    }

    .selectedRow {
        background-color: rgb(26, 54, 71);
    }
}

.stateTab{
    .inputTable{
      margin-bottom: 5px!important;  
    }
    height: calc(100vh - 140px);
}

.offensiveTable {
    // width: 550px;
    overflow: auto;

    tr>td {
        background-color: transparent;
    }
}

.defenseTable {
    // width: 0px;
    overflow: auto;

    tr>td {
        background-color: transparent;
    }
}

.featureBtnRow {
    // height: 35px;
    margin-bottom: 5px;

    button {
        border-radius: 3px;
        border: none;
        background-color: #009348;
        color: white;
        padding: 6px 10px;
        margin-left: 2px;
        margin-right: 2px;
    }

    button:hover {
        background-color: rgb(106, 204, 106);
    }

    input {
        padding: 4px 5px;
        border-radius: 5px;
        border: 1px solid grey;
    }
}

.sheet-name-container {
    align-items: center;
    padding: 5px 10px;
    // background-color: #f1f3f4;
    /* Light gray background */
    // border: 1px solid #d1d3d4;
    /* Slightly darker border */
    border-radius: 4px;
    /* Rounded corners */
    /* Adjust based on your preference */
}

.sheet-name-input {
    border: none;
    background-color: transparent;
    width: 100%;
    font-size: 16px;
    /* Adjust based on your preference */
    color: #ffffff;
    padding: 8px 10px;
    /* Dark gray text */
}

.sheet-name-input:focus {
    outline: none;
    border: 2px solid #4285f4;
    border-radius: 5px;
    /* Google's blue when focused */
}

.sheet-view-detail {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    color: green;
}

.sheet-view-detail:hover {
    text-decoration: none;
}

.featureBtnRow {
    select {
        border: 1px solid #009348;
        height: 25px;
    }

    select:focus {
        border: 1px solid #009348;
        outline: 1px solid #009348;
    }
}

.antd-Tbl .ant-table-thead .ant-table-cell {
    color: white;
}

.antd-Tbl .ant-table-cell {
    color: white;
}

.ant-select-selector {
    background-color: transparent !important;
    border: none !important;
}

.ant-select-selection-item {
    color: white !important;
}

.ant-select-arrow {
    color: white !important;
}

.ant-select-dropdown .ant-select-item {
    min-width: fit-content !important;
    padding-left: 2px !important;
}

.select-dropdown-trax {
    min-width: 100px !important;
}

.sheet-item {
    display: inline-block;
    text-align: center;
    color: white;
    padding-top: 3px;
    padding-bottom: 3px;
    height: 26px;
}

.inline-block {
    display: inline-block;
}

.underline {
    text-decoration: underline;
}

.blue-back {
    background-color: #3782a0;
}

.grey-back {
    background-color: #6d6d6d;
}

.red-back {
    background-color: #bb3838;
}

.bl {
    border-left: 1px solid #a3a3a3;
}

.br {
    border-right: 1px solid #a3a3a3;
}

.bt {
    border-top: 1px solid #a3a3a3;
}

.bb {
    border-bottom: 1px solid grey;
}

.MuiDrawer-paper {
    background-color: rgb(95, 95, 95) !important;
}

.MuiToolbar-regular {
    @media only screen and (max-width: 600px) {
        background-color: rgba(48, 41, 41, 1);
    }
}

.password-content {
    color: white;
}

.gn-ls {
    background-color: #04fc7c!important;
    color: black!important;
}

.loading-text {
    color: 'green';
    padding-left: '20px';
}

.word-slider {
    width: 100%;
    /* Adjust the width as needed */
    margin: auto;
    text-align: center;
}

.word-slider h3 {
    margin: 20px;
    padding: 20px;
    background: #f5f5f5;
    border: 1px solid #ddd;
}

/* Optional custom styles for arrows */
.slick-prev:before,
.slick-next:before {
  color: black; /* Change color to make arrows visible */
}

.slick-prev {
  left: -25px; /* Adjust positioning if needed */
}

.slick-next {
  right: -25px; /* Adjust positioning if needed */
}